.buttonGroup {
  display: flex;
  justify-content: space-around;
  text-align: center;
  border-radius: 50px;
}

.buttonGroupButton {
  padding: 10px 15px;
  width: 100%;
  background: var(--color-blue-dark);
  color: var(--color-white);
  font-size: 12px;
  cursor: pointer;
}

.buttonGroupButtonActive {
  background: var(--color-primary);
}

.buttonGroupButton:first-child {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.buttonGroupButton:last-child {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.buttonGroupButton:not(:first-child) {
  border-left: 1px solid var(--color-gray-light);
}
