.topBar {
  width: 100%;
  bottom: 0;
  background: var(--color-primary);
  display: grid;
  grid-template-columns: 1fr min-content;
  border-bottom: 1px solid var(--color-blue-medium);
  font-size: 20px;
  margin-top: auto;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 3px 0 3px 0;
}

.openButton {
  width: 50px;
  background: var(--color-blue-dark);
  color: white;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.activeLink {
  color: var(--color-background);
}
