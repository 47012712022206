.map {
  min-height: 300px;
  width: 100%;
  z-index: 0;
  margin-bottom: 20px;
  position: relative;
}

.large {
  height: 400px;
}

.roundIcon {
  border-radius: 100px;
  border: 5px solid var(--color-white);
}
