.bird {
  position: relative;
  text-decoration: none;
  color: inherit;
  text-align: center;
}

.birdName {
  font-size: 14px;
}

.birdLatinName {
  font-style: italic;
  font-size: 10px;
}

.imgContainer {
  width: 90%;
  height: 0;
  padding-top: 90%;
  position: relative;
  margin: 0 auto 5px auto;
  background: var(--color-gray);
  border-radius: 100%;
}

.imgContainer > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.notFound {
  filter: contrast(0.2);
}
