.bird {
  margin: 0 auto;
}

.birdInfo {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.birdInfo > img {
  border-radius: 100%;
  max-width: 80%;
  width: auto;
}

.name {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.latinName {
  font-style: italic;
  font-size: 14px;
  margin-bottom: 10px;
}

.enName {
  font-size: 14px;
  margin-bottom: 20px;
}

.finding {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 10px;
}

.label {
  font-weight: bold;
  text-align: end;
}

.notFound {
  filter: contrast(0.2);
}

.stars {
  margin-bottom: 20px;
}

.date {
  margin-bottom: 20px;
}

.link {
  margin-bottom: 20px;
}
.link > i {
  margin-left: 5px;
}

.datePicker {
  display: grid;
  grid-row-gap: 3px;
  z-index: 2000; /* To place over map */
}

.form {
  width: 100%;
  height: 100%;
  background: darkgray;
  padding: 20px 10px;
}

.findingModal {
  background: var(--color-white);
  color: darkslategray;
  padding: 20px;
  display: grid;
  grid-row-gap: 10px;
}

.notes {
  text-align: left;
  font-style: italic;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px dotted var(--color-gray-very-light);
  /* background: var(--color-gray-very-light);
  color: var(--color-primary); */
  border-radius: 5px;

}

