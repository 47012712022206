.listStats {
  background-color: var(--color-blue-dark);
  margin: -10px -20px 20px -20px;
  padding: 10px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
}

.birdGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-auto-rows: min-content;
  grid-gap: 10px;
  overflow-x: hidden;
}

.subtitle {
  text-align: center;
}

.searchField {
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
  position: relative;
}

.searchField > i {
  position: absolute;
  color: var(--color-gray);
  left: 15px;
}

.searchField > input {
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  padding: 10px 10px 10px 40px;
  border: 1px solid var(--color-primary);
  min-width: 110px;
}

.clearButton {
  font-size: 13px;
  background: var(--color-primary);
  color: var(--color-white);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  cursor: pointer;
}

.clearButton:active {
  background: var(--color-blue-dark);
}

.controls {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 5px;
  margin-bottom: 20px;
  position: sticky;
  top: -7px;
  z-index: 1;
}

.controls > :nth-child(1) {
  grid-column: 1 / span 2;
}
