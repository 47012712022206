.achievements {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-auto-rows: min-content;
  grid-gap: 20px;
}

.achievement {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  text-decoration: none;
  color: var(--color-white);
}

.image {
  margin-bottom: 7px;
  height: auto;
  width: 100%;
}

.notCompleted .image {
  filter: contrast(0.05);
}

.name {
  margin-bottom: auto;
  font-size: 15px;
  padding-bottom: 2px;
}
