@import url(https://fonts.googleapis.com/css?family=Montserrat);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-white: white;
  --color-gray-very-light: #eee;
  --color-gray-light: #aaa;
  --color-gray: #555;
  --color-background: #80bd9e;
  --color-blue-medium: #7ebfc5;
  --color-blue-dark: #55a2b4;
  --color-primary: #3d7582;
  --color-highlight: yellow;
}

body {
  touch-action: manipulation; /* Prevent iOS Safari to zoom on double-tap */
}

.app {
  min-height: 100vh;
  font-family: 'Montserrat  ', sans-serif;
  color: white;
  color: var(--color-white);
  background-color: #80bd9e;
  background-color: var(--color-background);
}

a {
  color: white;
  color: var(--color-white);
  text-decoration: none;
}

.appContent {
  overflow: auto;

  padding: 10px;
}

nav {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-around;
  background: #55a2b4;
  background: var(--color-blue-dark);
  align-items: center;
  font-size: 13px;
}

nav > a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #55a2b4;
  background-color: var(--color-blue-dark);
  color: white;
  color: var(--color-white);
  text-decoration: none;
  font-weight: bold;
}

nav > a:not(:first-child) {
  border-left: 2px solid #7ebfc5;
  border-left: 2px solid var(--color-blue-medium);
}

nav > a.active {
  background-color: #3d7582;
  background-color: var(--color-primary);
}

input {
  padding: 8px;
  border-radius: 3px;
  border: none;
  font-family: inherit;
  font-size: 13px;
  outline: none;
}

input::-webkit-input-placeholder {
  color: #aaa;
  color: var(--color-gray-light);
}

input::placeholder {
  color: #aaa;
  color: var(--color-gray-light);
}

.loadingSplash {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loadingSplash > img {
  border-radius: 100px;
  width: 170px;
  height: 170px;
  padding: 20px;
  background: white;
  background: var(--color-white);
  margin-bottom: 50px;
}

.loadingSplash > i {
  font-size: 50px;
}

.Birdex_listStats__37rdy {
  background-color: var(--color-blue-dark);
  margin: -10px -20px 20px -20px;
  padding: 10px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
}

.Birdex_birdGrid__17LsU {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  grid-gap: 10px;
  overflow-x: hidden;
}

.Birdex_subtitle__-e1zk {
  text-align: center;
}

.Birdex_searchField__zQXaT {
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
  position: relative;
}

.Birdex_searchField__zQXaT > i {
  position: absolute;
  color: var(--color-gray);
  left: 15px;
}

.Birdex_searchField__zQXaT > input {
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  padding: 10px 10px 10px 40px;
  border: 1px solid var(--color-primary);
  min-width: 110px;
}

.Birdex_clearButton__3uqDR {
  font-size: 13px;
  background: var(--color-primary);
  color: var(--color-white);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  cursor: pointer;
}

.Birdex_clearButton__3uqDR:active {
  background: var(--color-blue-dark);
}

.Birdex_controls__DJ_dj {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 5px;
  margin-bottom: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: -7px;
  z-index: 1;
}

.Birdex_controls__DJ_dj > :nth-child(1) {
  grid-column: 1 / span 2;
}

.ButtonGroup_buttonGroup__2S1xm {
  display: flex;
  justify-content: space-around;
  text-align: center;
  border-radius: 50px;
}

.ButtonGroup_buttonGroupButton__1ml29 {
  padding: 10px 15px;
  width: 100%;
  background: var(--color-blue-dark);
  color: var(--color-white);
  font-size: 12px;
  cursor: pointer;
}

.ButtonGroup_buttonGroupButtonActive__1KjER {
  background: var(--color-primary);
}

.ButtonGroup_buttonGroupButton__1ml29:first-child {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.ButtonGroup_buttonGroupButton__1ml29:last-child {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.ButtonGroup_buttonGroupButton__1ml29:not(:first-child) {
  border-left: 1px solid var(--color-gray-light);
}

.BirdThumbnail_bird__JGYxK {
  position: relative;
  text-decoration: none;
  color: inherit;
  text-align: center;
}

.BirdThumbnail_birdName__3WSXa {
  font-size: 14px;
}

.BirdThumbnail_birdLatinName__2pKrm {
  font-style: italic;
  font-size: 10px;
}

.BirdThumbnail_imgContainer__3eSkv {
  width: 90%;
  height: 0;
  padding-top: 90%;
  position: relative;
  margin: 0 auto 5px auto;
  background: var(--color-gray);
  border-radius: 100%;
}

.BirdThumbnail_imgContainer__3eSkv > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.BirdThumbnail_notFound__1wBjK {
  -webkit-filter: contrast(0.2);
          filter: contrast(0.2);
}

.Map_map__2S7Si {
  min-height: 300px;
  width: 100%;
  z-index: 0;
  margin-bottom: 20px;
  position: relative;
}

.Map_large__P5Ed8 {
  height: 400px;
}

.Map_roundIcon__1GqDl {
  border-radius: 100px;
  border: 5px solid var(--color-white);
}

.Bird_bird__2WJYs {
  margin: 0 auto;
}

.Bird_birdInfo__1tdhd {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Bird_birdInfo__1tdhd > img {
  border-radius: 100%;
  max-width: 80%;
  width: auto;
}

.Bird_name__2IM1s {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.Bird_latinName__2V6HK {
  font-style: italic;
  font-size: 14px;
  margin-bottom: 10px;
}

.Bird_enName__12RFZ {
  font-size: 14px;
  margin-bottom: 20px;
}

.Bird_finding__sOL-r {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 10px;
}

.Bird_label__-Wcvn {
  font-weight: bold;
  text-align: end;
}

.Bird_notFound__2HqK7 {
  -webkit-filter: contrast(0.2);
          filter: contrast(0.2);
}

.Bird_stars__1AXmR {
  margin-bottom: 20px;
}

.Bird_date__eEiMG {
  margin-bottom: 20px;
}

.Bird_link__2IZzV {
  margin-bottom: 20px;
}
.Bird_link__2IZzV > i {
  margin-left: 5px;
}

.Bird_datePicker__16VXc {
  display: grid;
  grid-row-gap: 3px;
  z-index: 2000; /* To place over map */
}

.Bird_form__ghf2J {
  width: 100%;
  height: 100%;
  background: darkgray;
  padding: 20px 10px;
}

.Bird_findingModal__1SU-o {
  background: var(--color-white);
  color: darkslategray;
  padding: 20px;
  display: grid;
  grid-row-gap: 10px;
}

.Bird_notes__922eC {
  text-align: left;
  font-style: italic;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px dotted var(--color-gray-very-light);
  /* background: var(--color-gray-very-light);
  color: var(--color-primary); */
  border-radius: 5px;

}


.Button_button__3UQR5 {
  padding: 20px;
  text-align: center;
  border-radius: 100px;
  font-weight: bolder;
  min-width: 150px;
  cursor: pointer;
}

.Button_basic__3VIW5 {
  border: 1px solid var(--color-white);
}

.Button_primary__2o-6d {
  background: var(--color-blue-dark);
  color: var(--color-white);
}

.Button_secondary__15dGV {
  background: var(--color-blue-medium);
  color: var(--color-white);
}

.Button_alert__2aJMm {
  background: red;
  color: var(--color-white);
}

.Button_small__1u_3R {
  padding: 10px 15px 10px 15px;
  min-width: 0;
  font-weight: normal;
}

.Achievements_achievements__1v9d1 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  grid-gap: 20px;
}

.Achievements_achievement__12Yi3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  text-decoration: none;
  color: var(--color-white);
}

.Achievements_image__31hzy {
  margin-bottom: 7px;
  height: auto;
  width: 100%;
}

.Achievements_notCompleted__7nlDy .Achievements_image__31hzy {
  -webkit-filter: contrast(0.05);
          filter: contrast(0.05);
}

.Achievements_name__Tp9G6 {
  margin-bottom: auto;
  font-size: 15px;
  padding-bottom: 2px;
}

.ProgressBar_superWrapper__16No1 {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-column-gap: 3px;
}

.ProgressBar_superWrapper__16No1.ProgressBar_withIcon__2NxtG {
  grid-template-columns: 1fr 20px;
}

.ProgressBar_progressBarContainer__1tyq6 {
  position: relative;
  color: var(--color-white);
}

.ProgressBar_progressBar__XZbqh {
  flex: 1 1;
  height: 16px;
  background: var(--color-primary);
  border-radius: 50px;
}

.ProgressBar_progressBarProgress__w8bgP {
  background: var(--color-blue-dark);
  height: 100%;
  border-radius: 50px;
}

.ProgressBar_progressBarLabel__3tT9P {
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  font-size: 12px;
  font-weight: bolder;
}

.Achievement_achievement__bWcro {
  max-width: 400px;
  margin: 0 auto;
}

.Achievement_achievementInfo__pGPQu {
  text-align: center;
}

.Achievement_name__3kySU {
  font-size: 30px;
}

.Achievement_badge__Er6R- {
  max-width: 50%;
  margin: 10px auto;
}

.Achievement_notCompleted__2yZ1r {
  -webkit-filter: contrast(0.1);
          filter: contrast(0.1);
}

.Achievement_description__2aGqH {
  margin: 20px 0;
}

.Achievement_birds__3XZZf {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  grid-gap: 10px;
  overflow-x: hidden;
  margin-top: 40px;
}

.Achievement_achievementIcon__3xgLM {
  width: 30px;
}

.SignIn_signIn__eSzbT {
  text-align: center;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.SignIn_loginButton__2Z83h {
  padding: 10px;
  background: var(--color-white);
  color: var(--color-gray);
  font-size: 12px;
}

.SignIn_loginButton__2Z83h > span {
  margin-right: 5px;
}

.SignIn_logo__SO4sA {
  border-radius: 150%;
  max-width: 170px;
  max-height: 170px;
  padding: 30px;
  background: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.SignIn_logo__SO4sA > img {
  width: 115px;
}

.SignIn_help__1_sg3 {
  text-align: left;
  margin-top: 40px;
}

.SignIn_help__1_sg3 a {
  color: var(--color-primary);
}

.TopBar_topBar__1Gx4_ {
  width: 100%;
  bottom: 0;
  background: var(--color-primary);
  display: grid;
  grid-template-columns: 1fr -webkit-min-content;
  grid-template-columns: 1fr min-content;
  border-bottom: 1px solid var(--color-blue-medium);
  font-size: 20px;
  margin-top: auto;
}

.TopBar_content__1hQw1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 3px 0 3px 0;
}

.TopBar_openButton__mazuD {
  width: 50px;
  background: var(--color-blue-dark);
  color: white;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.TopBar_activeLink__1jEnx {
  color: var(--color-background);
}

.Profile_paper__3rJcl {
    padding: 20px;
}
.About_about__2v-0B a {
  color: var(--color-primary);
}

.SideMenu_menu__BhnmU {
  height: 100%;
  width: 250px;
  background: var(--color-primary);
  display: grid;
  grid-template-rows: -webkit-min-content 1fr -webkit-min-content;
  grid-template-rows: min-content 1fr min-content;
}

.SideMenu_birderText__23Xp7 {
  font-size: 24px;
}

.SideMenu_version__1i0Hf {
  font-size: 10px;
}

.SideMenu_menuTop__2ShfI {
  display: grid;
  grid-template-columns: -webkit-min-content 1fr;
  grid-template-columns: min-content 1fr;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid var(--color-blue-dark);
}

.SideMenu_logoLine__3v2I8 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SideMenu_logo__2bRAu {
  width: 35px;
  height: 35px;
  border-radius: 100px;
  padding: 5px;
  background: var(--color-white);
  margin-right: 10px;
}

.SideMenu_closeButton__2KiRV {
  width: 50px;
  height: 50px;
  background: var(--color-blue-dark);
  color: white;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.SideMenu_email__1vCVp {
  font-size: 13px;
}

.SideMenu_menuContent__yOY5l {
  display: grid;
  align-self: center;
  grid-row-gap: 20px;
  justify-self: center;
  text-align: center;
}

.SideMenu_menuContent__yOY5l > a {
  text-decoration: none;
  font-size: 18px;
}

.SideMenu_menuBottom__254LA {
  border-top: 1px solid var(--color-blue-dark);
  padding: 20px 0;
  display: grid;
  grid-row-gap: 10px;
  justify-content: center;
  text-align: center;
}

.SideMenu_logOut__1Uj-Z {
  cursor: pointer;
}

.SideMenu_logOut__1Uj-Z > i {
  margin-right: 5px;
}

.SideMenu_new__1CYgo {
  color: white;
  background: red;
  font-size: 10px;
  padding: 4px 8px 3px 8px;
  vertical-align: middle;
  border-radius: 4px;
}

.SideMenu_activeLink__1oQ2U {
  color: var(--color-background);
}


